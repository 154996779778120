<template>
  <div>
    <vx-card class="mt-8">
        <div class="card-title">
            <h2>Invoices</h2>          
        </div>
        <div class="common-table">      
            <div class="table-row-select">
                <vs-select label="Show Entries" v-model="perPage" class="show-select" autocomplete>
                <vs-select-item
                    :key="index"
                    :value="item.value"
                    :text="item.text"
                    v-for="(item,index) in recordList"
                />
                </vs-select>
            </div>   
            <vs-table ref="tableData" :data="invoiceList" search :max-items="perPage" pagination class="user-table">
                <template slot="thead">  
                    <vs-th ></vs-th>              
                    <vs-th sort-key="sortableCreatedDate">{{ LabelConstant.tableHeaderLabelDateCreated }}</vs-th>
                    <vs-th sort-key="Transaction_Type">{{ LabelConstant.tableHeaderLabelAction }}</vs-th>
                    <vs-th sort-key="sortableGrandTotal">{{ LabelConstant.tableHeaderLabelAmount }}</vs-th>
                    <vs-th sort-key="Title">{{ LabelConstant.tableHeaderLabelNotes }}</vs-th>
                </template>
                <template slot-scope="{data}">
                    <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                        <vs-td>
                        <vs-button
                            @click="reportPopup=true, viewInvoice(tr.Id)"
                            :title="LabelConstant.buttonTitleViewDetails"                        
                            type="filled"
                            icon-pack="feather"
                            icon="icon-search"
                            class="mr-0"
                            color="primary"
                        ></vs-button>
                        </vs-td>
                        <vs-td :data="tr.sortableCreatedDate">
                            {{tr.Date_Created}}
                        </vs-td>
                        <vs-td :data="tr.Transaction_Type">
                            <vs-chip color="success">{{tr.Transaction_Type}}</vs-chip>
                        </vs-td>
                        <vs-td :data="tr.sortableGrandTotal">
                            {{tr.Grand_Total}}
                        </vs-td>
                        <vs-td :data="tr.Title">
                            {{tr.Title}}
                        </vs-td>
                    </vs-tr>
                </template>
            </vs-table>
            <p class="table-data-entries-details" v-if="!isSearch && isTableRecordEntriesDisplay">Showing {{showingDataFrom}} to {{showingDataTo}} of {{invoiceList.length|tableRecordNumberFormatter}} entries</p>
            <p class="table-data-entries-details" v-if="isSearch && isTableRecordEntriesDisplay">Showing {{showingDataFrom}} to {{showingSearchedDataTo}} of {{totalSearchedData|tableRecordNumberFormatter}} entries (filtered from {{invoiceList.length|tableRecordNumberFormatter}} entries)</p>
            <p class="table-data-entries-details" v-if="!isTableRecordEntriesDisplay">Showing 0 to 0 of 0 entries </p>                    
        </div>            
        
		</vx-card> 
        <!-- Create New Drip Email -->
        <vs-popup class="popup-width tag-info report-popup close-open" title="Receipt" :active.sync="reportPopup"> 
            <vs-button @click="closeReportPopup()" color="secondary" type="filled" class="mb-8 float-right close-popup-btn" >{{ LabelConstant.buttonLabelClose }}</vs-button>
            <template id="addContent">
                <div id="addContent">
                    <div id="reportViewer1">loading...</div>
                </div>
            </template>
            <div class="float-right mt-6 mb-8">
                <vs-button color="secondary" @click="closeReportPopup()" type="filled" class="ml-4"> {{ LabelConstant.buttonLabelClose }} </vs-button>
            </div>            
        </vs-popup>     
  </div>
</template>

<script>
import VxCard from "@/components/vx-card/VxCard";
import "../../assets/ReportViewer/js/telerikReportViewer-13.2.19.1030.min.js";

/* Filter */
import vSelect from 'vue-select'

export default {
    components: {
        VxCard,    
        vSelect,
    },
    data() {
        return {
            invoiceList: [],
            /* Table */
            perPage: 5,
            recordList: [
                {text: '5', value: 5},
                {text: '10', value: 10},
                {text: '25', value: 25},
                {text: '50', value: 50},
                {text: '100', value: 100}
            ],
            
            reportPopup: false,
            Report_Server: 'reporting.id-visitors.com',
            Report_Endpoint: '/api/reports/',
            Report_File_Name: 'ReportInvoiceTest2.trdx',
        };
    },
    created(){
        this.getInvoiceList()
    },
    methods: {
        getInvoiceList(){
            this.$vs.loading()
            this.axios.get("/ws/Invoice/GetInvoiceList").then(response => {
                let data = response.data;
                this.invoiceList = data
                this.invoiceList.map(function(manipulatedData) {
                    manipulatedData.sortableCreatedDate = new Date(manipulatedData.Date_Created);
                    manipulatedData.sortableGrandTotal = parseFloat(manipulatedData.Grand_Total.slice(1))
                    return manipulatedData;
                });
                
                this.invoiceList.sort(this.GetSortOrder("Transaction_Type", 'ASC')); //Pass the attribute to be sorted on
                this.$vs.loading.close()
            }).catch(e => {
                this.$vs.loading.close()
                this.showError(e);
            });
        },
        viewInvoice(id){
            let that = this;
            let uid = this.$route.params.id;
            let reportPara = {
                paramCustomerId: this.$store.state.companyAccountId, 
                paramBillingTransactionId: id
            }

            window.jQuery("#reportViewer1").telerik_ReportViewer({
                authenticationToken: that.$store.state.authTokenVV[uid].token,
                serviceUrl:
                "https://" +
                this.Report_Server +
                this.Report_Endpoint,
                reportSource: {
                    report: this.Report_File_Name,
                    parameters: reportPara
                },
                viewMode: "PRINT_PREVIEW",
                scaleMode:"FIT_PAGE_WIDTH",
                scale: 1.0,
                sendEmail: { enabled: false }
            });
        },
        closeReportPopup(){
            window.jQuery("#reportViewer1").remove()
            window.jQuery("#addContent").html('<div id="reportViewer1">loading...</div>')
            this.reportPopup=false
        }
    },
    computed: {
		isTableRecordEntriesDisplay(){
			if(this.invoiceList.length > 0){
				if(this.$refs.tableData.datax.length > 0){
					return true
				}else{
					return false
				}
			}else{
				return false
			}
		},
        isSearch: function () {
            if(this.invoiceList.length > 0){
                if(this.$refs.tableData.searchx == null || this.$refs.tableData.searchx == ""){
                    return false
                }else{
                    return true
                }
            }
        },
        showingDataFrom: function () {
            if(this.invoiceList.length > 0){
                return ((this.$refs.tableData.currentx - 1) * this.perPage) + 1 
            }
        },
        showingDataTo: function () {
            if(this.invoiceList.length > 0){
                let totalPages = this.$refs.tableData.getTotalPages
                if(totalPages == this.$refs.tableData.currentx){
                    return ((this.$refs.tableData.currentx - 1) * this.perPage) + this.$refs.tableData.datax.length
                }else{
                    return ((this.$refs.tableData.currentx) * this.perPage)
                }
            }
        },
        totalSearchedData: function () {
            if(this.invoiceList.length > 0){
                return this.$refs.tableData.queriedResults.length
            }
        },
        showingSearchedDataTo: function () {
            if(this.invoiceList.length > 0){
                let totalPages = this.$refs.tableData.getTotalPagesSearch
                if(totalPages == this.$refs.tableData.currentx){
                    return ((this.$refs.tableData.currentx - 1) * this.perPage) + this.$refs.tableData.datax.length
                }else{
                    return ((this.$refs.tableData.currentx) * this.perPage)
                }
            }
        }
    }
};
</script>
